import { Component, Input, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IdeaGenerationServiceService } from '../../services/idea-generation-service.service';
import { ItopThemes } from '../../../../core/models/idea-generation.model';
import { Store } from '@ngrx/store';
import { submitSearch } from '../../../../state/action';

@Component({
  selector: 'app-top-themes',
  templateUrl: './top-themes.component.html',
  styleUrls: ['./top-themes.component.css'],
  standalone:true,
  imports: [CommonModule,],

})
export class TopThemesComponent {
  @Input() market!: string;
constructor(private ideaGenService:IdeaGenerationServiceService,private store:Store){

}
topThemes:ItopThemes[]=[];
showSpinner: boolean = false;
ngOnChanges(changes: SimpleChanges) {
  if (changes['market'] && changes['market'].currentValue) {
    if (this.market === 'developed') {
      this.getDevelopedMarkets();
    } else if (this.market === 'global') {
      this.getGlobalMarkets();
    }
  }
}
  getDevelopedMarkets() {
    this.showSpinner=true;
    this.ideaGenService.getDevelopedMarketsTopThemes().subscribe({
      next: (res) => {
        this.topThemes=res.payload;
        this.showSpinner = false;
        // console.log('top themes-developed',res);
      },
      error: (err) => {
        this.showSpinner = false;

        alert("Developed markets trends loading failed!")
      },
    });
  }

  getGlobalMarkets() {
    this.showSpinner=true;

    this.ideaGenService.getGlobalMarketsTopThemes().subscribe({
      next: (res) => {
        // console.log('top themes-global',res);
        this.topThemes=res.payload;
        this.showSpinner = false;


      },
      error: (err) => {
        alert("Global markets trends loading failed!");
        this.showSpinner = false;


      },
    });
  }
  openTopThemeSearch(query: string) {
    alert(query);
  }

  submitTheme(themeInfo:ItopThemes){
    const searchParams = { 'themes': [{ 'api_query': themeInfo.query ,'name':themeInfo.theme}] };
    this.store.dispatch(submitSearch({ searchParams, searchType: 'theme' }));
  }
}
