<div class="container-fluid">
  <div class="row">
    <h1 style="font-size: 24px; color:black; text-align: left;margin-left:20px;margin-right:20px;">
      {{ tab_name | titlecase}} </h1>

    <div class="col m-10" style="margin:20px;">
      <div style="margin-bottom:10px;">
        The table below shows companies that are active with at least 1 invention in this area in the last 20 years,
        ranked based on the level of their innovative output in this area.
      </div>
      <div class="card keywords" style="padding:20px;">

        <h3>Top Companies in Theme</h3>
        <div class="spinner-border text-primary" role="status"
          *ngIf="top_companies_data.length==0 && !top_companies_loading_failed">
          <span class="visually-hidden">Loading...</span>
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="top_companies_loading_failed">
          <p>
            Unfortunately, we weren't able to load the top companies. Please try again later, or for more assistance,
            please contact
            <a href="mailto:info@quant-ip.com" style="color: blue">info&#64;quant-ip.com</a>.
          </p>
        </div>

        <div class="filters"   *ngIf="top_companies_data.length>0 && !top_companies_loading_failed">

          <mat-slide-toggle (change)="filterFavorites()">
            Show Selection Only
          </mat-slide-toggle>


          <input type="text" class="form-control" placeholder="Search by Company Name" [(ngModel)]="searchQuery"
            (ngModelChange)="applyFilters()" style="width: 270px;">



        </div>
        <table class="table   table-hover table-condensed "
          *ngIf="top_companies_data.length>0 && !top_companies_loading_failed">
          <thead>
            <tr style="background-color: #96c242;">
              <th scope="col" style="width: 70px;"></th>
              <th scope="col" style="width: 70px;">Rank</th>

              <th scope="col">Company</th>
              <th scope="col"  style="width: 150px;">
                <div class="dropdown" style=" font-weight:normal;
">
                  <button class="btn country-dropdown-btn dropdown-toggle" type="button" id="dropdownMenuButton"
                    data-bs-toggle="dropdown" aria-expanded="false" style="    font-size: 14px;">
                    <!-- Select Countries -->Country / Market 
                  </button>
                  <div class="dropdown-menu p-3" aria-labelledby="dropdownMenuButton"
                    style="width: 270px; max-height: 300px; overflow-y: auto;">
                    <!-- Loop through each region -->
                    <div *ngFor="let region of countryFilterOptions">
                      <!-- Region Header -->
                      <div class="d-flex justify-content-between align-items-center">
                        <div>
                          <input type="checkbox" [checked]="isRegionFullySelected(region)"
                            (change)="toggleRegionSelection(region)" id="{{ region.region }}-region-checkbox"
                            class="form-check-input me-2">
                          <label for="{{ region.region }}-region-checkbox" class="form-check-label">
                            {{ region.region }}
                          </label>
                        </div>
                        <button class="btn btn-sm btn-light"
                          (click)="toggleExpand(region.region); $event.stopPropagation();">
                          <i class="bi" [ngClass]="isExpanded(region.region) ? 'bi-chevron-up' : 'bi-chevron-down'"></i>
                        </button>
                      </div>
                      <!-- Countries in the Region -->
                      <div *ngIf="isExpanded(region.region)" class="ms-3">
                        <div *ngFor="let country of region.countries" class="form-check">
                          <input type="checkbox" class="form-check-input" [id]="'country-' + country.code"
                            [checked]="(countriesControl.value ?? []).includes(country.code)"
                            (change)="toggleCountrySelection(country.code, region)">
                          <label class="form-check-label" [for]="'country-' + country.code">
                            {{ country.name }}
                          </label>
                        </div>
                      </div>
                      <hr class="my-2" *ngIf="region !== countryFilterOptions[countryFilterOptions.length - 1]">
                    </div>
                  </div>
                </div>

              </th>


              <th scope="col" > In-Theme Invention Count (3y)
              </th>
              <th scope="col">In-Theme Invention Count (20y)
              </th>


              <th scope="col"> In-Theme Relative Growth (3y, %)</th>
              <th scope="col" >In-Theme Quality Score (3y)
              </th>
              <th scope="col" >Total Invention Count (20y)
              </th>

              <th > Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let company of filteredCompanyList | paginate: {id:'pag_1', itemsPerPage: 20, currentPage: paginator_1_page }">
              <td style="    width: 70px;">
                <button (click)="toggleFavorite(company)" class="btn btn-light" style="border: none;">
                  <i class="bi" [ngClass]="company.favorite ? 'bi-star-fill text-warning' : 'bi-star'"></i>
                </button>
              </td>
              <td style="width: 70px;">{{ company.rank }}</td>

              <td>
                <div class="text" style="max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                " matTooltip="{{company.entity_name}}">
                  {{ company.entity_name }}
                </div>
                <div class="isin" style="font-size: 12px;color: cadetblue;">
                  {{company.isin}} <a
                    [href]="company.webpage && company.webpage.startsWith('http') ? company.webpage : (company.webpage ? 'http://' + company.webpage : '#')"
                    target="_blank">
                    <i class="bi bi-box-arrow-up-right"></i>
                  </a>
                </div>
              </td>
              <td style="width: 150px;">{{company.country}}</td>


              <td >{{ company.invention_count_3y | number: '1.0-1' }}</td>
              <td >{{ company.invention_count_20y | number: '1.0-1' }}</td>

              <td>{{ company.relative_growth_3y | number: '1.0-1' }} %</td>
              <td >{{company.quality_average | number: '1.1-1'}}</td>
              <td >{{company.all_invention_count_20y | number: '1.0-1'}}</td>

              <td>
                <button class="btn search-button"  (click)="newSearch(company)">
                  <i class="bi bi-box-arrow-up-right" style="color: black;font-size: 18px;"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <pagination-controls id="pag_1" [maxSize]="5" (pageChange)="paginator_1_page = $event"
          style="align-items: flex-start;" *ngIf="filteredCompanyList.length>0">
        </pagination-controls>


      </div>
    </div>





  </div>
</div>