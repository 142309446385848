import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { take, switchMap, tap, of } from 'rxjs';
import { IinnovationQuality, IPeerGroupCompetitors, ITopCompanies } from '../../../../../../core/models/results.model';
import { loadSidenavData, submitSearch } from '../../../../../../state/action';
import { AppState } from '../../../../../../state/app.state';
import { selectSidenavDataByTabIdAndKey } from '../../../../../../state/selectors';
import { GeneralInnovationActivityResultsService } from '../../../../services/general-innovation-activity-results.service';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { MaterialModule } from '../../../../../../../Material-Module';
import { MatSlideToggle, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MainNavbarComponent } from '../../../../../../core/components/main-navbar/main-navbar.component';
import { REGION_CODES } from '../../../../../../../../public/country-region-codes';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatOptionModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-peer-group',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, NgxPaginationModule, ReactiveFormsModule, FormsModule, MatFormFieldModule, MatSelectModule, MatSlideToggleModule, MainNavbarComponent, MatTooltipModule, MatOptionModule, MatCheckboxModule, MatAutocompleteModule, MatInputModule, MatIconModule],
  templateUrl: './peer-group.component.html',
  styleUrl: './peer-group.component.css'
})
export class PeerGroupComponent implements OnInit, OnChanges {
  @Input() tabId!: string;
  @Input() query: string | null = null;
  @Input() gsubind: string[] | null = null;
  @Input() entityids: string[] | null = null;
  @Input() tab_name: string | null = null;

  showResults: boolean = false;
  competitors_list_loading_failed: boolean = false;
  competitors_list: IPeerGroupCompetitors[] = [];
  paginator_1_page: number = 1;
  filteredCompanyList: IPeerGroupCompetitors[] = [];
  showOnlyFavorites: boolean = false;
  searchQuery: string = '';
  countriesControl = new FormControl<string[]>([]);
  expandedRegions: Set<string> = new Set();

  countryFilterOptions = REGION_CODES;

  constructor(
    private store: Store<AppState>,
    private apiService: GeneralInnovationActivityResultsService
  ) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tabId']) {
      this.resetState();
      if (this.gsubind && this.gsubind[0] != null) {
        this.getCompetitors();
      }
    }
  }

  resetState(): void {
    this.showResults = false;
    this.competitors_list = [];
    this.competitors_list_loading_failed = false;
  }

  entityid: string = '';
  getCompetitors(): void {
    this.entityid = this.entityids![0];
    this.showOnlyFavorites = false;
    this.showResults = true;
    this.store
      .select(selectSidenavDataByTabIdAndKey(this.tabId, 'company_detailed_technology_benchmark'))
      .pipe(
        take(1),
        switchMap((data) => {
          if (!data) {
            return this.apiService.getCompetitiveBenchmarkingPeerGroup(this.entityids, this.gsubind).pipe(
              tap((res) => {
                if (res.payload != null) {
                  this.store.dispatch(
                    loadSidenavData({
                      tabId: this.tabId,
                      sidenavKey: 'company_detailed_technology_benchmark',
                      data: res.payload,
                    })
                  );
                  this.processCompetitorData(res.payload);
                } else {
                  this.competitors_list_loading_failed = true;
                }
              })
            );
          } else {
            this.competitors_list_loading_failed = false;
            this.processCompetitorData(data);
            return of();
          }
        })
      )
      .subscribe({
        // next: () =>console.log ('Competitor data processed.'),
        error: () => (this.competitors_list_loading_failed = true),
      });
  }

  processCompetitorData(data: IPeerGroupCompetitors[]): void {
    this.competitors_list = data.map((company) => ({
      ...company,
      favorite: company.favorite ?? false,
    }));
    this.filteredCompanyList = [...this.competitors_list];
    this.applyFilters();
  }

  toggleFavorite(company: IPeerGroupCompetitors): void {
    const updatedCompany = { ...company, favorite: !company.favorite };
    const index = this.competitors_list.indexOf(company);
    if (index !== -1) {
      this.competitors_list[index] = updatedCompany;
    }
    this.applyFilters();
  }

  toggleExpand(region: string): void {
    if (this.expandedRegions.has(region)) {
      this.expandedRegions.delete(region);
    } else {
      this.expandedRegions.add(region);
    }
  }

  isExpanded(region: string): boolean {
    return this.expandedRegions.has(region);
  }

  toggleRegionSelection(region: any): void {
    const allSelected = this.isRegionFullySelected(region);
    const regionCountryCodes = region.countries.map((c: any) => c.code);

    if (allSelected) {
      const updatedSelection = (this.countriesControl.value ?? []).filter(
        (value: string) => !regionCountryCodes.includes(value)
      );
      this.countriesControl.setValue(updatedSelection);
    } else {
      const updatedSelection = Array.from(
        new Set([...(this.countriesControl.value ?? []), ...regionCountryCodes])
      );
      this.countriesControl.setValue(updatedSelection);
    }
    this.applyFilters();
  }

  toggleCountrySelection(countryCode: string, region: any): void {
    const currentSelection = this.countriesControl.value ?? [];
    const isSelected = currentSelection.includes(countryCode);

    if (isSelected) {
      this.countriesControl.setValue(
        currentSelection.filter((value: string) => value !== countryCode)
      );
    } else {
      this.countriesControl.setValue([...currentSelection, countryCode]);
    }
    this.applyFilters();
  }

  isRegionFullySelected(region: any): boolean {
    const regionCountryCodes = region.countries.map((c: any) => c.code);
    const selectedCodes = this.countriesControl.value ?? [];
    return regionCountryCodes.every((code: string) => selectedCodes.includes(code));
  }
  filterFavorites(): void {
    this.showOnlyFavorites = !this.showOnlyFavorites;
    this.applyFilters();
  }

  applyFilters(): void {
    this.paginator_1_page = 1;
    this.filteredCompanyList = this.competitors_list;

    if (this.showOnlyFavorites) {
      this.filteredCompanyList = this.filteredCompanyList.filter((company) => company.favorite);
      this.paginator_1_page = 1;
    }

    if (this.searchQuery) {
      this.filteredCompanyList = this.filteredCompanyList.filter((company) =>
        company.entity_name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    }

    if (this.countriesControl.value?.length) {
      this.filteredCompanyList = this.filteredCompanyList.filter((company) =>
        this.countriesControl.value!.includes(company.country)
      );
    }
  }


  newSearch(company: IPeerGroupCompetitors) {
    const searchParams = { 'companies': [company] };

    this.store.dispatch(submitSearch({ searchParams, searchType: 'company' }));
  }
}