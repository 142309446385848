
export const REGION_CODES = [
    {
        region: "Developed Markets",
        countries: [
            { name: "Australia", code: "AU" },
            { name: "Austria", code: "AT" },
            { name: "Belgium", code: "BE" },
            { name: "Canada", code: "CA" },
            { name: "Denmark", code: "DK" },
            { name: "Finland", code: "FI" },
            { name: "France", code: "FR" },
            { name: "Germany", code: "DE" },
            { name: "Hong Kong", code: "HK" },
            { name: "Ireland", code: "IE" },
            { name: "Israel", code: "IL" },
            { name: "Italy", code: "IT" },
            { name: "Japan", code: "JP" },
            { name: "Netherlands", code: "NL" },
            { name: "New Zealand", code: "NZ" },
            { name: "Norway", code: "NO" },
            { name: "Portugal", code: "PT" },
            { name: "Singapore", code: "SG" },
            { name: "Spain", code: "ES" },
            { name: "Switzerland", code: "CH" },
            { name: "United Kingdom", code: "GB" },
            { name: "United States", code: "US" },
        ],
    },
    {
        region: "Emerging Markets ",
        countries: [{ name: "Brazil", code: "BR" },
        { name: "Chile", code: "CL" },
        { name: "China", code: "CN" },
        { name: "Colombia", code: "CO" },
        { name: "Czech Republic", code: "CZ" },
        { name: "Egypt", code: "EG" },
        { name: "Greece", code: "GR" },
        { name: "Hungary", code: "HU" },
        { name: "India", code: "IN" },
        { name: "Indonesia", code: "ID" },
        { name: "Kuwait", code: "KW" },
        { name: "Malaysia", code: "MY" },
        { name: "Mexico", code: "MX" },
        { name: "Peru", code: "PE" },
        { name: "Philippines", code: "PH" },
        { name: "Poland", code: "PL" },
        { name: "Qatar", code: "QA" },
        { name: "Saudi Arabia", code: "SA" },
        { name: "South Africa", code: "ZA" },
        { name: "South Korea", code: "KR" },
        { name: "Taiwan", code: "TW" },
        { name: "Thailand", code: "TH" },
        { name: "Turkey", code: "TR" },
        { name: "United Arab Emirates", code: "AE" }],
    },
    {
        region: "North America",
        countries: [
            { name: "United States", code: "US" },
            { name: "Canada", code: "CA" },
        ],
    },
    
    {
        region: "Europe",
        countries: [
            { name: "Switzerland", code: "CH" },
            { name: "Ukraine", code: "UA" },
            { name: "United Kingdom", code: "GB" },
            { name: "Andorra", code: "AD" },
            { name: "Austria", code: "AT" },
            { name: "Belgium", code: "BE" },
            { name: "Bulgaria", code: "BG" },
            { name: "Croatia", code: "HR" },
            { name: "Cyprus", code: "CY" },
            { name: "Czechia", code: "CZ" },
            { name: "Denmark", code: "DK" },
            { name: "Estonia", code: "EE" },
            { name: "Finland", code: "FI" },
            { name: "France", code: "FR" },
            { name: "Germany", code: "DE" },
            { name: "Greece", code: "GR" },
            { name: "Hungary", code: "HU" },
            { name: "Iceland", code: "IS" },
            { name: "Ireland", code: "IE" },
            { name: "Italy", code: "IT" },
            { name: "Latvia", code: "LV" },
            { name: "Liechtenstein", code: "LI" },
            { name: "Lithuania", code: "LT" },
            { name: "Luxembourg", code: "LU" },
            { name: "Malta", code: "MT" },
            { name: "Monaco", code: "MC" },
            { name: "Montenegro", code: "ME" },
            { name: "Netherlands", code: "NL" },
            { name: "Norway", code: "NO" },
            { name: "Poland", code: "PL" },
            { name: "Portugal", code: "PT" },
            { name: "Romania", code: "RO" },
            { name: "San Marino", code: "SM" },
            { name: "Serbia", code: "RS" },
            { name: "Slovakia", code: "SK" },
            { name: "Slovenia", code: "SI" },
            { name: "Spain", code: "ES" },
            { name: "Sweden", code: "SE" },
            { name: "Vatican City", code: "VA" },
        ],
    }, {
        region: "APAC",
        countries: [{ name: "Afghanistan", code: "AF" },
        { name: "American Samoa", code: "AS" },
        { name: "Australia", code: "AU" },
        { name: "Bangladesh", code: "BD" },
        { name: "Bhutan", code: "BT" },
        { name: "British Indian Ocean Territory", code: "IO" },
        { name: "Cambodia", code: "KH" },
        { name: "China", code: "CN" },
        { name: "Christmas Island", code: "CX" },
        { name: "Cocos (Keeling) Islands", code: "CC" },
        { name: "Cook Islands", code: "CK" },
        { name: "Fiji", code: "FJ" },
        { name: "French Polynesia", code: "PF" },
        { name: "Guam", code: "GU" },
        { name: "Hong Kong", code: "HK" },
        { name: "India", code: "IN" },
        { name: "Indonesia", code: "ID" },
        { name: "Japan", code: "JP" },
        { name: "Kiribati", code: "KI" },
        { name: "Macao", code: "MO" },
        { name: "Malaysia", code: "MY" },
        { name: "Maldives", code: "MV" },
        { name: "Marshall Islands", code: "MH" },
        { name: "Mongolia", code: "MN" },
        { name: "Myanmar", code: "MM" },
        { name: "Nauru", code: "NR" },
        { name: "Nepal", code: "NP" },
        { name: "New Caledonia", code: "NC" },
        { name: "New Zealand", code: "NZ" },
        { name: "Niue", code: "NU" },
        { name: "Norfolk Island", code: "NF" },
        { name: "North Korea", code: "KP" },
        { name: "Northern Mariana Islands", code: "MP" },
        { name: "Pakistan", code: "PK" },
        { name: "Palau", code: "PW" },
        { name: "Papua New Guinea", code: "PG" },
        { name: "Philippines", code: "PH" },
        { name: "Russia", code: "RU" },
        { name: "Samoa", code: "WS" },
        { name: "Singapore", code: "SG" },
        { name: "Solomon Islands", code: "SB" },
        { name: "South Korea", code: "KR" },
        { name: "Sri Lanka", code: "LK" },
        { name: "Taiwan", code: "TW" },
        { name: "Thailand", code: "TH" },
        { name: "Timor-Leste", code: "TL" },
        { name: "Tokelau", code: "TK" },
        { name: "Tonga", code: "TO" },
        { name: "Tuvalu", code: "TV" },
        { name: "Vanuatu", code: "VU" },
        { name: "Vietnam", code: "VN" },
        { name: "Wallis and Futuna", code: "WF" }]
    },{
        region: "Eurozone",
        countries: [
            { name: "Andorra", code: "AD" },
            { name: "Austria", code: "AT" },
            { name: "Belgium", code: "BE" },
            { name: "Cyprus", code: "CY" },
            { name: "Estonia", code: "EE" },
            { name: "Finland", code: "FI" },
            { name: "France", code: "FR" },
            { name: "Germany", code: "DE" },
            { name: "Greece", code: "GR" },
            { name: "Ireland", code: "IE" },
            { name: "Italy", code: "IT" },
            { name: "Latvia", code: "LV" },
            { name: "Lithuania", code: "LT" },
            { name: "Luxembourg", code: "LU" },
            { name: "Malta", code: "MT" },
            { name: "Monaco", code: "MC" },
            { name: "Montenegro", code: "ME" },
            { name: "Netherlands", code: "NL" },
            { name: "Portugal", code: "PT" },
            { name: "San Marino", code: "SM" },
            { name: "Slovakia", code: "SK" },
            { name: "Slovenia", code: "SI" },
            { name: "Spain", code: "ES" },
            { name: "Vatican City", code: "VA" },
        ],
    },
    {
        region: "EMEA",
        countries: [
            // Middle East & Africa 
            { name: "Algeria", code: "DZ" },
            { name: "Angola", code: "AO" },
            { name: "Armenia", code: "AM" },
            { name: "Azerbaijan", code: "AZ" },
            { name: "Bahrain", code: "BH" },
            { name: "Belarus", code: "BY" },
            { name: "Benin", code: "BJ" },
            { name: "Bosnia and Herzegovina", code: "BA" },
            { name: "Botswana", code: "BW" },
            { name: "Burkina Faso", code: "BF" },
            { name: "Burundi", code: "BI" },
            { name: "Cameroon", code: "CM" },
            { name: "Cape Verde", code: "CV" },
            { name: "Central African Republic", code: "CF" },
            { name: "Chad", code: "TD" },
            { name: "Comoros", code: "KM" },
            { name: "Congo", code: "CG" },
            { name: "Democratic Republic of the Congo", code: "CD" },
            { name: "Djibouti", code: "DJ" },
            { name: "Egypt", code: "EG" },
            { name: "Equatorial", code: "GQ" },
            // Europe
            { name: "Switzerland", code: "CH" },
            { name: "Ukraine", code: "UA" },
            { name: "United Kingdom", code: "GB" },
            { name: "Andorra", code: "AD" },
            { name: "Austria", code: "AT" },
            { name: "Belgium", code: "BE" },
            { name: "Bulgaria", code: "BG" },
            { name: "Croatia", code: "HR" },
            { name: "Cyprus", code: "CY" },
            { name: "Czechia", code: "CZ" },
            { name: "Denmark", code: "DK" },
            { name: "Estonia", code: "EE" },
            { name: "Finland", code: "FI" },
            { name: "France", code: "FR" },
            { name: "Germany", code: "DE" },
            { name: "Greece", code: "GR" },
            { name: "Hungary", code: "HU" },
            { name: "Iceland", code: "IS" },
            { name: "Ireland", code: "IE" },
            { name: "Italy", code: "IT" },
            { name: "Latvia", code: "LV" },
            { name: "Liechtenstein", code: "LI" },
            { name: "Lithuania", code: "LT" },
            { name: "Luxembourg", code: "LU" },
            { name: "Malta", code: "MT" },
            { name: "Monaco", code: "MC" },
            { name: "Montenegro", code: "ME" },
            { name: "Netherlands", code: "NL" },
            { name: "Norway", code: "NO" },
            { name: "Poland", code: "PL" },
            { name: "Portugal", code: "PT" },
            { name: "Romania", code: "RO" },
            { name: "San Marino", code: "SM" },
            { name: "Serbia", code: "RS" },
            { name: "Slovakia", code: "SK" },
            { name: "Slovenia", code: "SI" },
            { name: "Spain", code: "ES" },
            { name: "Sweden", code: "SE" },
            { name: "Vatican City", code: "VA" },

        ],
    },
   
   
    {
        region: "DACH",
        countries: [
            { name: "Germany", code: "DE" },
            { name: "Austria", code: "AT" },
            { name: "Switzerland", code: "CH" },
        ],
    },
];

