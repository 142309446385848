
import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { PlotlyModule } from 'angular-plotly.js';
import { GeneralInnovationActivityResultsService } from '../../../../services/general-innovation-activity-results.service';
import { take, switchMap, of, tap, Observable } from 'rxjs';
import { selectActiveTabDetails, selectSidenavDataByTabIdAndKey, selectTabById } from '../../../../../../state/selectors';
import { AppState, Iparams, Tab } from '../../../../../../state/app.state';
import { ITechnologyFootprint } from '../../../../../../core/models/results.model';
import { loadSidenavData } from '../../../../../../state/action';

@Component({
  selector: 'app-innovation-activity',
  standalone: true,
  imports: [CommonModule,PlotlyModule],
  templateUrl: './innovation-activity.component.html',
  styleUrl: './innovation-activity.component.css'
})
export class InnovationActivityComponent implements OnInit,OnChanges {
  @Input() tabId!: string;
  @Input() query: string | null = null;
  @Input() entityids: string[] | null = null;
  @Input() tab_name: string | null = null;

  tabs$!: Observable<Tab | undefined>;
  showPlot: boolean=false;
  constructor(private store:Store<AppState>,private apiService:GeneralInnovationActivityResultsService
  ){

  }
  showTechnologyFootprint: boolean = false;
  footprintData: any = [];
  patentActivityPlot: any;
ngOnInit(): void {

}

ngOnChanges(changes: SimpleChanges): void {
  if (changes['tabId']) {
    this.tabs$ = this.store.select(selectActiveTabDetails);
    this.patent_activity_data_loading_failed=false;
    this.footprint_data_loading_failed=false;
    this.showPlot=false;
    this.showTechnologyFootprint=false;
    this.patentActivityData=[];
    this.footprintData=[];
    this.patentActivity();
   this.techFootprint();

  }
}

patent_activity_data_loading_failed:boolean = false;
patentActivityData:any=[];
patentActivity(){
  this.store.select(selectSidenavDataByTabIdAndKey(this.tabId, 'patent_activity')).pipe(
    take(1),
    switchMap((data) => {
      if (!data) {
        return this.apiService.getInnovationActivity(this.query, this.entityids).pipe(
          tap((patentActivityData) => {

            if(patentActivityData.payload!=null){
              this.store.dispatch(loadSidenavData({
                tabId: this.tabId,
                sidenavKey: 'patent_activity',
                data: patentActivityData.payload
              }));

              this.patentActivityData = patentActivityData.payload;
              this.plotActivity();
            }else{
              this.patent_activity_data_loading_failed=true;
            }

          })
        );
      } else {
        this.patent_activity_data_loading_failed=false;
        this.patentActivityData = data;
        this.plotActivity();
        return of();
      }
    })
  ).subscribe({
    next: () => {
    },
    error: (err) => {
      this.patent_activity_data_loading_failed=true;

    }
  });
}

  plotActivity() {
    const countByYearData = this.patentActivityData;
    const years = countByYearData?.map((item: { ype: any }) => item.ype);
    const counts = countByYearData?.map((item: { invention_count: any }) => item.invention_count);

    this.patentActivityPlot = {
      data: [{
        x: years,
        y: counts,
        type: 'bar',
        marker: {
          'color': '#a2c613'
        }
      }],
      layout: {
        title: {
          text: 'Patent Activity Over Time',
          font: {
              size: 20,
              family: 'Arial, sans-serif',
              color: 'black',
              weight: 'bold'
          }
      },
        annotations: [
          {
            xref: 'paper',
            yref: 'paper',
            x: 0.5,
            y: 1.04,
            xanchor: 'center',
            yanchor: 'bottom',
            text: '* The latest year is incomplete and considered year-to-date. ',
            showarrow: false,
            font: {
              size: 12,
              color: 'gray'
            }
          }],
        yaxis: { title: 'Invention Count' },
        xaxis: { title: 'Year', tickmode: 'linear', tick0: years![0], dtick: 1 },
        width: 500,
        showlegend: false,
        legend: { "orientation": "h" }
      }
    };
    this.showPlot = true;
  }


footprint_data_loading_failed:boolean = false;
techFootprint() {
  this.store.select(selectSidenavDataByTabIdAndKey(this.tabId, 'technology_footprint_data')).pipe(
    take(1),
    switchMap((data) => {
      if (!data) {
        return this.apiService.getTechnologyFootprint(this.query, this.entityids).pipe(
          tap((footprintData) => {

            if(footprintData.payload!=null){
              this.store.dispatch(loadSidenavData({
                tabId: this.tabId,
                sidenavKey: 'technology_footprint_data',
                data: footprintData.payload
              }));

              this.footprintData = footprintData.payload;
              this.plotTechFootprint();
            }else{
              this.footprint_data_loading_failed=true;
            }

          })
        );
      } else {
        this.footprint_data_loading_failed=false;
        this.footprintData = data;
        this.plotTechFootprint();
        return of();
      }
    })
  ).subscribe({
    next: () => {
    },
    error: (err) => {
      this.footprint_data_loading_failed=true;

    }
  });
}

techFootprintGraph: any = [];
plotTechFootprint() {
 const colors =  ["#00C9A7","#56751c","#5e1075","#ff9500","#7c0000","#1f10bc","#96c242","#a442c2","#6cd4ff","#000000","#ffd84d"];
  const footprint: ITechnologyFootprint[] = this.footprintData;
  const years = [...new Set(footprint.map(item => item.ype))];


  const techfields: string[] = [];
  footprint.forEach(item => {
  if (item.tf !== null && item.tf !== undefined && !techfields.includes(item.tf)) {
    techfields.push(item.tf);
  }
});



  const techfieldColors: { [key: string]: string } = {};
  techfields.forEach((techfield, index) => {
    techfieldColors[techfield] = techfield === 'Others' ? '#808080' : colors[index];
  });


  const totalCounts: { [key: number]: number } = {};
  years.forEach(year => {
    totalCounts[year] = footprint.reduce((acc: number, item: ITechnologyFootprint) => {
      if (item.ype === year) {
        return acc + item.focus;
      }
      return acc;
    }, 0);
  });

  const data = techfields.map(techfield => {
    const techfieldData = years.map(year => {
      const dataPoint = footprint.find(item => item.tf === techfield && item.ype === year);
      return dataPoint ? dataPoint.focus : 0;
    });

    return {
      x: years,
      y: techfieldData,
      type: 'bar',
      name: techfield,
      marker: { color: techfieldColors[techfield] },
      hovertemplate: 'Year: %{x}<br>Value: %{y:.1f}%',
    };
  });

  const layout = {
    title: {
      text: 'Technology Footprint Over Time',
      font: {
          size: 20,
          family: 'Arial, sans-serif',
          color: 'black',
          weight: 'bold'
      }
  },
        annotations: [
          {
            xref: 'paper',
            yref: 'paper',
            x: 0.5,
            y: 1.04,
            xanchor: 'center',
            yanchor: 'bottom',
            text: '* The latest year is incomplete and considered year-to-date. ',
            showarrow: false,
            font: {
              size: 12,
              color: 'gray'
            }
          }],
    barmode: 'stack',
    xaxis: { title: 'Year', tickmode: 'linear', tick0: years[0], dtick: 1 },
    yaxis: { title: 'Percentage%' },
    //  width: 500,
    showlegend: true,
    legend: {
      orientation: 'h',
       y: -0.5,
      // x: 0.5,
      // xanchor: 'center',
      // yanchor: 'top',
      itemsizing: 'constant',

      font: { size: 10 },
    }
  };

// Ensure "Others" is the first item in the data array
const sortedData = data.sort((a, b) => {
  if (a.name === 'Others') return 1;
  if (b.name === 'Others') return -1;
  return 0;
});

// Assign the sorted data to the graph
this.techFootprintGraph = {
  data: sortedData,
  layout: layout
};
  this.showTechnologyFootprint = true;
}




}
