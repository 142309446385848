import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IdeaGenStateService implements OnInit {
  // Holds the selected option ('topThemes' or 'themeTrends')
  private selectedOptionSubject = new BehaviorSubject<string>('topThemes');
  // Holds the selected market ('developed' or 'global')
  private selectedMarketSubject = new BehaviorSubject<string>('developed');
  private selectedQipHighlightsSubject = new BehaviorSubject<string>('uent_country any "RU"');


  selectedOption$ = this.selectedOptionSubject.asObservable();
  selectedMarket$ = this.selectedMarketSubject.asObservable();


  ngOnInit(): void {
   this.setSelectedOption('topThemes','developed')
  }

  selectedQipHighlight$ = this.selectedQipHighlightsSubject.asObservable();
  constructor() {}

  setSelectedOption(option: string, market: string) {
    this.selectedOptionSubject.next(option);
    this.selectedMarketSubject.next(market);
  }
  setQipHighlightsTheme(qipHighlightsTheme:string) {
    this.selectedQipHighlightsSubject.next(qipHighlightsTheme);
  }


}
