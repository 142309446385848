import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { take, switchMap, tap, of } from 'rxjs';
import { IinnovationQuality, ITopCompanies } from '../../../../../../core/models/results.model';
import { loadSidenavData, submitSearch } from '../../../../../../state/action';
import { AppState } from '../../../../../../state/app.state';
import { selectSidenavDataByTabIdAndKey } from '../../../../../../state/selectors';
import { GeneralInnovationActivityResultsService } from '../../../../services/general-innovation-activity-results.service';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { REGION_CODES } from '../../../../../../../../public/country-region-codes';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-thematic-competition-overview',
  standalone: true,
  imports: [CommonModule,NgxPaginationModule,MatTooltipModule,ReactiveFormsModule,FormsModule,MatSlideToggleModule],
  templateUrl: './thematic-competition-overview.component.html',
  styleUrl: './thematic-competition-overview.component.css'
})
export class ThematicCompetitionOverviewComponent  implements OnInit,OnChanges {
  @Input() tabId!: string;
  @Input() query: string | null = null;
  @Input() entityids: string[] | null = null;
  @Input() tab_name: string | null = null;

  constructor(private store:Store<AppState>,private apiService:GeneralInnovationActivityResultsService
  ){

  }
  paginator_1_page: number = 1;
  params: any;
ngOnInit(): void {
}

ngOnChanges(changes: SimpleChanges): void {
  if (changes['tabId']) {
    this.top_companies_loading_failed=false;
    this.top_companies_data=[];
   this.topCompanies();

  }
}


top_companies_loading_failed:boolean = false;
top_companies_data: ITopCompanies[] = [];
filteredCompanyList: ITopCompanies[] = [];
showOnlyFavorites: boolean = false;
searchQuery: string = '';
countriesControl = new FormControl<string[]>([]); 
expandedRegions: Set<string> = new Set(); 

countryFilterOptions = REGION_CODES; 
topCompanies() {
    this.store.select(selectSidenavDataByTabIdAndKey(this.tabId, 'top_companies')).pipe(
      take(1),
      switchMap((data) => {
        if (!data) {
          return this.apiService.getTopCompanies(this.query, null).pipe(
            tap((res) => {

              if(res.payload!=null){
                this.store.dispatch(loadSidenavData({
                  tabId: this.tabId,
                  sidenavKey: 'top_companies',
                  data: res.payload
                }));
                this.top_companies_data = res.payload;
                this.processData(this.top_companies_data);
              }else{
                this.top_companies_loading_failed=true;
              }



              // this.plotInnovationQuality();
            })
          );
        } else {
          this.top_companies_data = data;
          this.processData(this.top_companies_data);

          // this.plotInnovationQuality();

          return of();
        }
      })
    ).subscribe({
      next: () => {
        // console.log('innovationQuality data detailed processing complete.');
      },
      error: (err) => {
        // console.error('Error fetching tech innovationQuality data detailed:', err);
        this.top_companies_loading_failed=true;
      }
    });
  }

  processData(data: ITopCompanies[]): void {
    this.top_companies_data = data.map((company) => ({
      ...company,
      favorite: company.favorite ?? false,
    }));
    this.filteredCompanyList = [...this.top_companies_data];
    this.applyFilters();
  }


  toggleFavorite(company: ITopCompanies): void {
    const updatedCompany = { ...company, favorite: !company.favorite };
    const index = this.top_companies_data.indexOf(company);
    if (index !== -1) {
      this.top_companies_data[index] = updatedCompany;
    }
    this.applyFilters();
  }

  toggleExpand(region: string): void {
    if (this.expandedRegions.has(region)) {
      this.expandedRegions.delete(region);
    } else {
      this.expandedRegions.add(region);
    }
  }

  isExpanded(region: string): boolean {
    return this.expandedRegions.has(region);
  }

  toggleRegionSelection(region: any): void {
    const allSelected = this.isRegionFullySelected(region);
    const regionCountryCodes = region.countries.map((c: any) => c.code);

    if (allSelected) {
      const updatedSelection = (this.countriesControl.value ?? []).filter(
        (value: string) => !regionCountryCodes.includes(value)
      );
      this.countriesControl.setValue(updatedSelection);
    } else {
      const updatedSelection = Array.from(
        new Set([...(this.countriesControl.value ?? []), ...regionCountryCodes])
      );
      this.countriesControl.setValue(updatedSelection);
    }
    this.applyFilters();
  }

  toggleCountrySelection(countryCode: string, region: any): void {
    const currentSelection = this.countriesControl.value ?? [];
    const isSelected = currentSelection.includes(countryCode);

    if (isSelected) {
      this.countriesControl.setValue(
        currentSelection.filter((value: string) => value !== countryCode)
      );
    } else {
      this.countriesControl.setValue([...currentSelection, countryCode]);
    }
    this.applyFilters();
  }

  isRegionFullySelected(region: any): boolean {
    const regionCountryCodes = region.countries.map((c: any) => c.code);
    const selectedCodes = this.countriesControl.value ?? [];
    return regionCountryCodes.every((code: string) => selectedCodes.includes(code));
  }
  filterFavorites(): void {
    this.showOnlyFavorites = !this.showOnlyFavorites;
    this.applyFilters(); 
  }
  
  applyFilters(): void {
    this.paginator_1_page=1;
    this.filteredCompanyList = this.top_companies_data;

    if (this.showOnlyFavorites) {
      this.filteredCompanyList = this.filteredCompanyList.filter((company) => company.favorite);
      this.paginator_1_page = 1;
    }

    if (this.searchQuery) {
      this.filteredCompanyList = this.filteredCompanyList.filter((company) =>
        company.entity_name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    }

    if (this.countriesControl.value?.length) {
      this.filteredCompanyList = this.filteredCompanyList.filter((company) =>
        this.countriesControl.value!.includes(company.country)
      );
    }
  }

  newSearch(company: ITopCompanies) {
    const searchParams = { 'companies': [company] };

    this.store.dispatch(submitSearch({ searchParams, searchType: 'company' }));
  }
}
