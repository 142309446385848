import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { PlotlyModule } from 'angular-plotly.js';
import { Observable, take, switchMap, tap, of } from 'rxjs';
import { IClusteringFootprint } from '../../../../../../core/models/clustering.model';
import { ITechnologyFootprint } from '../../../../../../core/models/results.model';
import { loadSidenavData } from '../../../../../../state/action';
import { Tab, AppState } from '../../../../../../state/app.state';
import { selectActiveTabDetails, selectSidenavDataByTabIdAndKey } from '../../../../../../state/selectors';
import { GeneralInnovationActivityResultsService } from '../../../../services/general-innovation-activity-results.service';
import { ClusteringService } from '../clustering.service';

@Component({
  selector: 'app-cluster-treemap',
  standalone: true,
  imports: [CommonModule,PlotlyModule],
  templateUrl: './cluster-treemap.component.html',
  styleUrl: './cluster-treemap.component.css'
})
export class ClusterTreemapComponent  implements OnInit,OnChanges {
  @Input() tabId!: string;
  @Input() query: string | null = null;
  @Input() entityids: string[] | null = null;
  @Input() tab_name: string | null = null;
  @Input() qipHighlights: boolean = false;

  tabs$!: Observable<Tab | undefined>;
  showClusteringPlot: boolean=false;
  clusters_footprint_data_loading_failed: boolean= false;
  constructor(private store:Store<AppState>,
    private clusteringService:ClusteringService
  ){

  }

ngOnInit(): void {

}

ngOnChanges(changes: SimpleChanges): void {
  if (changes['tabId']) {
    this.tabs$ = this.store.select(selectActiveTabDetails);
   this.getTreemapData();
  }
  // Check if the query or qipHighlights has changed
  if (changes['query'] || changes['qipHighlights']) {
    // If qipHighlights is true and query indicates "qipHighlights", call the method
    if (this.qipHighlights && this.tabId === 'qipTabId') {
      // console.log('qipHighlights is true and query is qipHighlights', this.qipHighlights);
      this.qipHighlightsTreemaps();
    }
  }
}
themeCataloguePlot = { data: null, layout: null };
treemaps_data_loading_failed:boolean = false;
showTreemap:boolean = false;
getTreemapData() {
  this.showTreemap=false;
  this.themeCataloguePlot.data = null;
  this.themeCataloguePlot.layout = null;
  this.store.select(selectSidenavDataByTabIdAndKey(this.tabId, 'cluster_treemap_data')).pipe(
    take(1),
    switchMap((data) => {
      if (!data) {
        return this.clusteringService.getClusterTreemapData(this.query, this.entityids).pipe(
          tap((treemapData) => {

            if(treemapData.payload!=null){
              this.store.dispatch(loadSidenavData({
                tabId: this.tabId,
                sidenavKey: 'cluster_treemap_data',
                data: treemapData.payload
              }));

              const plotData = JSON.parse(treemapData.payload); 
              this.themeCataloguePlot.data = plotData.data;
              this.themeCataloguePlot.layout = plotData.layout;
              this.showTreemap=true;

            }else{
              this.treemaps_data_loading_failed=true;
              this.showTreemap=false;

            }

          })
        );
      } else {
        const plotData = JSON.parse(data); 
        this.themeCataloguePlot.data = plotData.data;
        this.themeCataloguePlot.layout = plotData.layout;
        this.showTreemap=true;

        return of();
      }
    })
  ).subscribe({
    next: () => {
    },
    error: (err) => {
      this.treemaps_data_loading_failed=true;

    }
  });
}

qipHighlightsTreemaps() {
  this.showTreemap=false;
  this.themeCataloguePlot.data = null;
  this.themeCataloguePlot.layout = null;
 
  this.clusteringService.getClusterTreemapData(this.query, this.entityids).subscribe({
    next: (treemapData) => {
      const plotData = JSON.parse(treemapData.payload); 
      this.themeCataloguePlot.data = plotData.data;
      this.themeCataloguePlot.layout = plotData.layout;
      this.showTreemap=true;
    },
    error: (err) => {
      this.treemaps_data_loading_failed=true;

    }
  });
}





}

