<nav class="navbar navbar-expand-lg navbar-light pa-0 main">
  <a class="navbar-brand" routerLink="/home">
    <img src="assets/QuantIP_Logo_white.svg" class="d-inline-block align-top img-fluid logo-img mt-2" alt="Quant IP Logo">
  </a>

  <div class="collapse navbar-collapse d-flex justify-content-between" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link btn btn-link" routerLink="/idea-generation" routerLinkActive="active" ariaCurrentWhenActive="page">Idea Generation</a>
      </li>
      <li class="nav-item">
        <a class="nav-link btn btn-link" routerLink="/company-search" routerLinkActive="active" ariaCurrentWhenActive="page">Stock Selection</a>
      </li>
      <li class="nav-item">
        <a class="nav-link btn btn-link" routerLink="/thematic-search" routerLinkActive="active" ariaCurrentWhenActive="page">Thematic Investing</a>
      </li>

      <!-- Conditionally display the Results link -->
      <li class="nav-item" *ngIf="hasTabs$ | async">
        <a class="nav-link btn btn-link" routerLink="/results" routerLinkActive="active" ariaCurrentWhenActive="page">Results</a>
      </li>

      <li class="nav-item" *ngIf="roles!.includes('Admin') ">
        <a class="nav-link btn btn-link" routerLink="/admin-panel" routerLinkActive="active" ariaCurrentWhenActive="page">Admin Panel</a>
      </li>
    </ul>

    <div>
      <button class="btn btn-primary" style="  background-color: #b39cd0;
  color: black;" (click)="logout()">
        Logout
      </button>
    </div>
  </div>
</nav>

<router-outlet></router-outlet>
