import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import * as PlotlyJS from 'plotly.js-dist-min';
import { PlotlyModule } from 'angular-plotly.js';
import { importProvidersFrom } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { SearchEffects } from './app/state/search_effect';
import { appConfig } from './app/app.config';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors } from '@angular/common/http';
import { versionCheckInterceptor } from './app/core/interceptors/version-check.interceptor';
import { tokenExpiryInterceptor } from './app/core/interceptors/token-expiry.interceptor';

PlotlyModule.plotlyjs = PlotlyJS;

bootstrapApplication(AppComponent, {
 providers: [
    provideHttpClient(
      withInterceptors([tokenExpiryInterceptor]) 
    ),
    ...appConfig.providers,  
  ],
})
  .catch((err) => console.error(err));
