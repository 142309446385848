import { Store } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, Validators } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { CompanySearchService } from '../../services/company-search.service';
import { ICompany } from '../../../../core/models/company.model';
import { NgxPaginationModule } from 'ngx-pagination';
import { MainNavbarComponent } from '../../../../core/components/main-navbar/main-navbar.component';
import { submitSearch } from '../../../../state/action';
import saveAs from 'file-saver';
import { MatTooltipModule } from '@angular/material/tooltip';
import {ClipboardModule} from '@angular/cdk/clipboard';
@Component({
  selector: 'app-company-search',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, NgxPaginationModule, ClipboardModule,MainNavbarComponent
  ,MatTooltipModule,FormsModule],
  templateUrl: './company-search.component.html',
  styleUrl: './company-search.component.css'
})
export class CompanySearchComponent implements OnInit {
  reportDownloadingV1: boolean = false;
  reportDownloadingV2: boolean = false;

  constructor(private companyService: CompanySearchService, private store: Store) { }
  ngOnInit(): void {
    this.reportPermissionsCheck();
  }
  paginator_1_page: number = 1;
  paginator_2_page: number = 1;
  showSpinner: boolean = false;
  companySearchForm = new FormGroup({
    name: new FormControl(),
    isin: new FormControl(),
    url: new FormControl("", [
      Validators.pattern(
        "^(https?:\/\/)?(www\.)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$"
      ),
    ]),
  });


  companies: ICompany[] = [];
  companySearch() {
    this.companies = [];
    this.showSpinner = true;
    this.paginator_1_page=1;
    this.paginator_2_page=1;
    this.companyService.companySearch(this.companySearchForm.value.name, this.companySearchForm.value.isin, this.companySearchForm.value.url).subscribe({
      next: (res) => {
        if (res.payload.length>0) {
          this.companies = res.payload;
          this.showSpinner = false;
        }
        else {
          alert('No public companies found')
          this.showSpinner = false;

        }
      }, error: () => {
        alert('Search Failed!');
        this.showSpinner = false;

      }
    })
  }


  selected_companies: ICompany[] = [];
  addCompany(selected: ICompany) {
    let exists = this.selected_companies.some(el => el.entityid === selected.entityid);
    //todo:remove this to make selection of multiple companies

    if (!exists) {
      this.selected_companies.push(selected);
      this.submitCompanies();
    }

  }
  removeCompany(selected: ICompany) {
    this.selected_companies = this.selected_companies.filter((el) => {
      return el.entityid != selected.entityid;
    })

  }

  submitCompanies() {
    const searchParams = { 'companies': this.selected_companies };
    this.store.dispatch(submitSearch({ searchParams, searchType: 'company' }));
  }


  roles: string[] = [];
  enableDownloadButton: boolean = false;
  reportPermissionsCheck() {

    const r = localStorage.getItem('roles');
    const user = localStorage.getItem('user');
    const token = localStorage.getItem('current_user_token');
    if (r) {
      try {
        this.roles = JSON.parse(r); // Parse roles as an array
      } catch (e) {
        // console.error("Failed to parse roles from localStorage:", e);
        this.roles = [];
      }
    }
  }

  downloadreportv2(company: ICompany) {
    this.reportDownloadingV2 = true;
    this.companyService.downloadPdfReportV2(company.companyid).subscribe({
      next: (res) => {
        var filename = res.headers.get("Content-Disposition")!;
        filename = filename.split("filename=")[1];
        saveAs(res.body!, filename);
        this.reportDownloadingV1 = false;
        this.reportDownloadingV2 = false;
      },
      error: (err) => {
        alert("Failed to generate report. Please contact info@quant-ip.com for assistance.");
        this.reportDownloadingV1 = false;
        this.reportDownloadingV2 = false;

      },
    })
  }

  downloadreportv1(company: ICompany) {
    this.reportDownloadingV1 = true;
    this.companyService.downloadPdfReportV1(company.entityid).subscribe({
      next: (res) => {
        var filename = res.headers.get("Content-Disposition")!;
        filename = filename.split("filename=")[1];
        saveAs(res.body!, filename);
        this.reportDownloadingV1 = false;
        this.reportDownloadingV2 = false;

      },
      error: () => {

        alert("Failed to download report!!");
        this.reportDownloadingV1 = false;
        this.reportDownloadingV2 = false;


      },
    })
  }

}


