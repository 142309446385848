import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { NgxPaginationModule } from 'ngx-pagination';
import { take, switchMap, tap, of } from 'rxjs';
import { IPeerGroupCompetitors } from '../../../../../../core/models/results.model';
import { loadSidenavData } from '../../../../../../state/action';
import { AppState } from '../../../../../../state/app.state';
import { selectSidenavDataByTabIdAndKey } from '../../../../../../state/selectors';
import { GeneralInnovationActivityResultsService } from '../../../../services/general-innovation-activity-results.service';
import { ClusteringService } from '../clustering.service';
import { IClusterPeerGroups } from '../../../../../../core/models/clustering.model';

@Component({
  selector: 'app-clustering-peer-group',
  standalone: true,
  imports: [CommonModule, NgxPaginationModule],
  templateUrl: './clustering-peer-group.component.html',
  styleUrl: './clustering-peer-group.component.css'
})
export class ClusteringPeerGroupComponent implements OnInit, OnChanges {
  @Input() tabId!: string;
  @Input() query: string | null = null;
  @Input() gsubind: string[] | null = null;
  @Input() entityids: string[] | null = null;
  @Input() tab_name: string | null = null;

  constructor(private store: Store<AppState>,
    private clusteringService: ClusteringService
  ) {

  }

  ngOnInit(): void {
  }
  showResults: boolean = false;
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tabId']) {
      this.showResults = false;
      this.competitors_list = [];
      this.competitors_list_loading_failed = false;

      if (this.gsubind && this.gsubind[0] != null) {
        this.getCompetitors();
      } else {
        this.showResults = false;
      }

    }
  }



  competitors_list_loading_failed: boolean = false;
  competitors_list: IClusterPeerGroups[] = [];
  paginator_1_page: number = 1;
  getCompetitors() {
    this.paginator_1_page = 1;
    this.showResults = true;
    this.store.select(selectSidenavDataByTabIdAndKey(this.tabId, 'clustering_peer_group')).pipe(
      take(1),
      switchMap((data) => {
        if (!data) {
          return this.clusteringService.getPeerGroup(this.entityids, this.gsubind).pipe(
            tap((res) => {


              if (res.payload != null) {
                this.store.dispatch(loadSidenavData({
                  tabId: this.tabId,
                  sidenavKey: 'clustering_peer_group',
                  data: res.payload
                }));
                this.competitors_list = res.payload;
              } else {
                this.competitors_list_loading_failed = true;
              }


            })
          );
        } else {
          this.competitors_list_loading_failed = false;
          this.competitors_list = data;
          return of();
        }
      })
    ).subscribe({
      next: () => {
      },
      error: (err) => {
        this.competitors_list_loading_failed = true;
      }
    });
  }

}
