<ul class="nav nav-tabs custom-tabs">
  <li class="nav-item" *ngFor="let tab of (tabs$ | async)" style="text-align: left;">
    <a class="nav-link tabss" [class.active]="tab.tabId === (activeTab$ | async)" (click)="onTabClick(tab.tabId)">
      <button type="button"  class="close-button" [class.active]="tab.tabId === (activeTab$ | async)"  (click)="removeTab(tab.tabId)">
        <i class="bi bi-x-circle-fill"></i>
      </button>
      {{tab.tab_name}}

    </a>

  </li>
</ul>