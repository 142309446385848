<div class="container">
  <h4 class="heading">Idea Generation</h4>
  <hr />
  <div class="row">
    <div class="col">
      <h3 class="heading">Top Technologies</h3>
      <ul class="list">
        <li (click)="handleOptionClick('topThemes', 'developed')"
          [ngClass]="{'list-item-active': isActive('topThemes', 'developed')}" class="list-item">
          Developed Markets
        </li>
        <li (click)="handleOptionClick('topThemes', 'global')"
          [ngClass]="{'list-item-active': isActive('topThemes', 'global')}" class="list-item">
          Global Markets
        </li>
      </ul>
      <br><br>
      <h3 class="heading">Technology Trends</h3>
      <ul class="list">
        <li (click)="handleOptionClick('themeTrends', 'developed')"
          [ngClass]="{'list-item-active': isActive('themeTrends', 'developed')}" class="list-item">
          Developed Markets
        </li>
        <li (click)="handleOptionClick('themeTrends', 'global')"
          [ngClass]="{'list-item-active': isActive('themeTrends', 'global')}" class="list-item">
          Global Markets
        </li>
      </ul>

      <br><br>
      <ng-container *ngIf="roles!.includes('alpha') ">
        <h3 class="heading">Quant IP Highlights</h3>
        <ul class="list">
          <li (click)="handleOptionClick('qipHighlights', 'Magnificent5')"
            [ngClass]="{'list-item-active': isActive('qipHighlights', 'Magnificent5')}" class="list-item">
            Magnificent 5
          </li>
          <li (click)="handleOptionClick('qipHighlights', 'US')"
            [ngClass]="{'list-item-active': isActive('qipHighlights', 'US')}" class="list-item">
            United States
          </li>
          <li (click)="handleOptionClick('qipHighlights', 'Europe')"
            [ngClass]="{'list-item-active': isActive('qipHighlights', 'Europe')}" class="list-item">
            Europe
          </li>
          <li (click)="handleOptionClick('qipHighlights', 'BRICS')"
            [ngClass]="{'list-item-active': isActive('qipHighlights', 'BRICS')}" class="list-item">
            BRICS
          </li>
          <li (click)="handleOptionClick('qipHighlights', 'China')"
            [ngClass]="{'list-item-active': isActive('qipHighlights', 'China')}" class="list-item">
            China
          </li>
          <!-- <li (click)="handleOptionClick('qipHighlights', 'Russia')"
            [ngClass]="{'list-item-active': isActive('qipHighlights', 'Russia')}" class="list-item">
            Russia
          </li> -->
        </ul>
      </ng-container>

    </div>
  </div>

</div>