<div class="container-fluid">
  <div class="row">
      <div class="col">
        <div class="card top-themes-card" *ngIf="topThemes" >
          <div class="top-themes-container">
            <div class="d-flex justify-content-center align-items-center">
              <div *ngIf="showSpinner" class="spinner-border text-primary"
                style="width: 6rem; height: 6rem;align-items: center;" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
            <h3>Top 3 Technologies by Growth </h3>
            <h4 style="font-size:16px;">Below are the technology areas  with the most significant growth in innovation activity in the last year.</h4>
            <table class="table   table-hover table-condensed ">
              <thead >
                <tr style="background-color: #96c242;">
                  <th style="width:20%">Theme</th>
                  <th style="width:10%">Growth</th>
                  <th style="width:10%">Growth Percentage</th>
                  <th style="width:15%">Keywords</th>
                  <th style="width:30%">Top Companies</th>
                  <th style="width:10%">Search</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let themeInfo of topThemes">
                  <td style="width:20%">{{themeInfo.theme}}</td>
                  <td style="width:10%">{{themeInfo.value}}</td>
                  <td  style="width:10%">{{themeInfo.growthPercentage}}%</td>
                  <td style="width:15%" [innerHTML]="themeInfo.keywords"></td>
                  <td style="width:30%" [innerHTML]="themeInfo.companies"></td>
                  <td style="width:10%">
                    <button class="btn search-button"  (click)="submitTheme(themeInfo)">
                      Search
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
</div>
