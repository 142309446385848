import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, concatMap, tap, map, withLatestFrom } from 'rxjs/operators';
import { of, from } from 'rxjs';
import { Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { Store } from '@ngrx/store';
import { AppState } from './app.state';
import { CompanySearchService } from '../features/company-search/services/company-search.service';
import {
  changeActiveSideTab,
  changeActiveTab,
  loadSidenavData,
  submitSearch,
  navigateToResults,
  addTab,
  setActiveTab,
} from './action';
import { GeneralInnovationActivityResultsService } from '../features/results/services/general-innovation-activity-results.service';

@Injectable({
  providedIn: 'root'
})
export class SearchEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private apiService: GeneralInnovationActivityResultsService,
    private store: Store<AppState>
  ) { }

  submitSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitSearch),
      switchMap(({ searchParams, searchType }) => {

        const newTabId = uuidv4();
        let params;
        let tab_name;
        let sideTab;
        if (searchType === 'company') {
          params = {
            entityids: [searchParams.companies[0].entityid],
            query: null,
            entity_data: searchParams.companies[0],
            query_data: null,
            gsubind: [searchParams.companies[0].gsubind]
          };
          tab_name = searchParams.companies[0].entity_name;
          sideTab='executive_summary'
        } else if (searchType === 'theme') {
          params = {
            entityids: null,
            query: searchParams.themes[0].api_query,
            entity_data: null,
            query_data: searchParams.themes[0],
            gsubind: null
          };
          tab_name = searchParams.themes[0].category
            ? `${searchParams.themes[0].category} - ${searchParams.themes[0].name}`
            : searchParams.themes[0].name;
            sideTab='innovation_activity'


        } else {
          return of();
        }

        const newTab = {
          tabId: newTabId,
          search_type: searchType,
          params: params,
          sidenav_results: {},
          tab_name: tab_name
        };

        return of(
          addTab({ tab: newTab }),
          changeActiveTab({ tabId: newTabId }),
          changeActiveSideTab({ sideTab: sideTab }),
          setActiveTab({ tab: newTab }),
          navigateToResults()
        );
      })
    )
  );
  navigateToResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(navigateToResults),
      tap(() => {
        this.router.navigate(['/results']);
      })
    ),
    { dispatch: false }
  );


}
